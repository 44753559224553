import { Button, Dropdown, Icon, Menu } from 'antd'
import { List } from 'immutable'
import moment from 'moment/moment'
import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import { Link, RouteComponentProps } from 'react-router-dom'
import {
  getAggregatesByCode,
  getDetailsByImei,
  getEmployeeByCode,
  getTransportByCode,
} from 'services/apiService/modules'
import { TTranslate } from 'types'
import { TransportPopupUnit } from 'ui/Map/Track/TrackItem/PopupWrap/TransportPopup'
import { MeasureType } from '../../constants'
import { LANG_RU } from '../../constants/lang'
import { GMT_MOSCOW } from '../../constants/time'
import { demoNewDetail, demoTrack } from '../../queries/authorization'
import { requestTrack, RequestTrackData, sortDetailData } from '../../queries/request'
import { AppStateType } from '../../reducers/store'
import { ConfigType, UserInfoType } from '../../reducers/user/state'
import { deleteTrack, startLoadTrack, updateDataMachine } from '../../reducers/users/actions'
import { UnitType, UserType } from '../../reducers/users/state'
import { link } from '../../router'
import { WrapSoloPicker } from '../../ui/Pickers/soloWrap'
import { cookMainInfoData, TMainInfoData } from './helpers'
import { TDetailInformation, TProps as TComposedProps } from './index'
import { TDriver, TEquipment } from './MainInfo'
import { TMachine } from './models'

const ButtonGroup = Button.Group

type TProps = {
  demoTrack: (imei: number) => void

  deleteTrack: (params: {
    id: number
    needTrackCurr: boolean
    dailyTrack: boolean
  }) => void

  config?: ConfigType

  history: RouteComponentProps['history']

  info: UserInfoType

  location?: {
    query?: {
      date?: string
    }

    search?: RouteComponentProps['location']['search']
  }

  machine: TMachine

  requestTrack: (params: RequestTrackData) => void

  requestTrackObservable: (params: RequestTrackData) => void

  translate: TTranslate
} & TComposedProps

type TLocalState = {
  carrier?: TransportPopupUnit
  day: moment.Moment
  detailInformation: TDetailInformation[] | null
  detailPosition?: {
    lat: number
    lon: number
  }
  driver: null | TDriver
  equipment: TEquipment | null
  errorTextDetail: null | string
  errorTextNotification: null | string
  load: boolean
  mainInfoData: TMainInfoData | null
  openDate: boolean
  track: []
}

export default (ComposedComponent: React.FC<TComposedProps>) => {
  class HOC extends React.Component<TProps, TLocalState> {
    constructor(props: TProps) {
      super(props)

      this.state = {
        carrier              : undefined,
        day                  : moment(),
        detailInformation    : null,
        detailPosition       : undefined,
        driver               : null,
        equipment            : null,
        errorTextDetail      : null,
        errorTextNotification: null,
        load                 : false,
        mainInfoData         : null,
        openDate             : false,
        track                : [],
      }
    }

    calendar: HTMLDivElement | null = null
    observer: ReturnType<typeof setInterval> | undefined

    componentWillUnmount() {
      if(!this.props.demoMode) {
        this.props.deleteTrack({
          id           : this.props.machine.id,
          needTrackCurr: false,
          dailyTrack   : true,
        })

        if(this.observer) {
          clearInterval(this.observer)
        }

        //@ts-expect-error
        document.removeEventListener('click', this.handleClickOutside, false)
      }
    }

    componentWillMount() {
      const searchParams = new URLSearchParams(this.props?.location?.search || '')

      if(searchParams.get('date')?.replace(/\D/g, '').length === 15) {
        this.setState({
          day: moment(searchParams.get('date'), 'YYMMDDHHmmss000'),
        })
      }

      //@ts-expect-error
      document.addEventListener('click', this.handleClickOutside, false)
    }

    componentDidMount() {
      if(this.props.machine && this.props.machine.imei) {
        this.successDetailData(this.props.machine)
      }
    }

    componentWillReceiveProps({ machine }: { machine: TMachine }) {
      if(!this.props.machine?.imei && machine.imei) {
        clearInterval(this.observer)
        this.successDetailData(machine)
      }
    }

    handleClickOutside = (e: React.ChangeEvent<HTMLInputElement>) => {
      const domNode = ReactDOM.findDOMNode(this.calendar)

      if(!domNode || !domNode.contains(e.target)) {
        this.setState({ openDate: false })
      }
    };

    successDetailData = (machine: TMachine) => {
      if(this.props.demoMode) {
        demoNewDetail((data: { registers: Record<string, object> }[]) =>
          this.setState({
            detailInformation: sortDetailData(
              data[0].registers,
              this.props.translate,
            ),

            mainInfoData: cookMainInfoData(data[0].registers),
          }),
        )

        this.props.demoTrack(this.props.machine.imei)
      } else if(
        this.state.day.format('YYMMDD') === moment().format('YYMMDD')
      ) {
        this.props.deleteTrack({
          id           : this.props.machine.id,
          needTrackCurr: false,
          dailyTrack   : true,
        })

        this.props.requestTrackObservable({
          from: moment().startOf('day').unix() * 1000,
          imei: machine.imei,
          to  : moment().unix() * 1000,
        })

        this.observer = setInterval(
          () => this.allRequests(machine.imei, true),
          30000,
        )

        this.allRequests(machine.imei, true)
      } else {
        if(this.observer) {clearInterval(this.observer)}

        this.props.deleteTrack({
          id           : this.props.machine.id,
          needTrackCurr: false,
          dailyTrack   : true,
        })

        this.props.requestTrack({
          from: this.state.day.startOf('day').unix() * 1000,
          to  : this.state.day.endOf('day').unix() * 1000,
          imei: machine.imei,
        })

        this.allRequests(machine.imei)
      }
    };

    // Получить информацию из справочника
    _getItemInfo = async (
      registers: Record<string, {value: number}>,
      itemName: string,
      platformRequest: (params?: unknown) => Promise<unknown>,
    ): Promise<unknown | undefined> => {
      //@ts-expect-error
      const prevCode = this.state[itemName.toLowerCase()] && this.state[itemName.toLowerCase()].code
      const newCode = registers[itemName] && registers[itemName].value

      if(newCode > 0 && prevCode !== newCode) {
        try {
          return await platformRequest({ code: newCode })
        } catch(err) { console.error(err) }
      } else if(prevCode === newCode) {
        //@ts-expect-error
        return this.state[itemName.toLowerCase()]
      }

      return undefined
    }

    allRequests = async (imei: number, today?: boolean) => {
      this.setState({ load: true })

      getDetailsByImei({
        from                 : this.state.day.startOf('day').toDate(),
        imei,
        lang                 : (this.props.info.lang || LANG_RU).toUpperCase(),
        timezoneOffsetMinutes: this.props.info.gmt ?? GMT_MOSCOW * 60,
        to                   : today ? moment().toDate() : this.state.day.endOf('day').toDate(),
      })
        .then(async data => {
          // @ts-expect-error неизвестный тип у data
          const registers = data[0].registers
          const lat = registers.LATITUDE ? registers.LATITUDE.value : null
          const lon = registers.LONGITUDE ? registers.LONGITUDE.value : null

          this.setState({

            //@ts-expect-error
            carrier          : await this._getItemInfo(registers, 'CARRIER', getTransportByCode),
            detailInformation: sortDetailData(
              registers,
              this.props.translate,
            ),

            detailPosition: lat && lon ? {
              lat,
              lon,
            } : undefined,

            //@ts-expect-error
            driver: await this._getItemInfo(registers, 'DRIVER', getEmployeeByCode),

            //@ts-expect-error
            equipment   : await this._getItemInfo(registers, 'EQUIPMENT', getAggregatesByCode),
            load        : false,
            mainInfoData: cookMainInfoData(registers),
          })
        })

        .catch(error => this.setState({ ...this.state, errorTextDetail: error, load: false }))
    };

    changeDate = (newDate: moment.Moment) => {
      this.setState(
        {
          day              : newDate,
          detailInformation: null,
          mainInfoData     : null,
          openDate         : false,
          track            : [],
        },
        () => {
          const query = new URLSearchParams(this.props.location?.search)

          query.set('date', `${newDate.format('YYMMDD')}235959000`)

          this.props.history.push({ search: query.toString() })
          this.successDetailData(this.props.machine)
        },
      )
    };

    dropAnalyticMenu = () => {
      const isCombine = ['ZUK', 'KUK'].includes(this.props.machine?.model)

      return (
        <Menu>
          <Menu.Item key="1">
            <Link
              to={`${link.watchTime}?machine=${
                this.props.machine?.id
              }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                'YYMMDD',
              )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                'YYMMDD',
              )}235959000&startFilter=true`}
            >
              {this.props.translate('use_of_time')}
            </Link>
          </Menu.Item>

          {!this.props.demoMode && this.props.machine?.data?.FUEL?.measure === MeasureType.Volume &&
            <Menu.Item key="2">
              <Link
                to={`${link.watchFueling}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959000&startFilter=true`}
              >
                {this.props.translate('drain')} /{' '}
                {this.props.translate('refuel')}
              </Link>
            </Menu.Item>
          }

          {!this.props.demoMode && this.props.machine?.data?.FUEL?.measure === MeasureType.Volume &&
            <Menu.Item key="3">
              <Link
                to={`${link.watchFuelReport}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959000&startFilter=true`}
              >
                {this.props.translate('fuel_report')}
              </Link>
            </Menu.Item>
          }

          <Menu.Item key="4">
            <Link
              to={`${link.watchPerformanceIndicators}?machine=${
                this.props.machine.id
              }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                'YYMMDD',
              )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                'YYMMDD',
              )}235959000&startFilter=true`}
            >
              {this.props.translate('indications_of_work')}
            </Link>
          </Menu.Item>

          {!this.props.demoMode && 
            <Menu.Item key="5">
              <Link
                to={`${link.watchGroupMachineryReports}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959000&startFilter=true`}
              >
                {this.props.translate('consolidated_statement_about_machines')}
              </Link>
            </Menu.Item>
          }

          { !this.props.demoMode &&
            <Menu.Item key="6">
              <Link
                to={`${link.watchMachineReports}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959999&startFilter=true`}
              >
                {this.props.translate('report_about_machine')}
              </Link>
            </Menu.Item>
          }

          { !this.props.demoMode &&
            <Menu.Item key="7">
              <Link
                to={`${link.watchAgroOperations}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959000&startFilter=true`}
              >
                {this.props.translate('events_a_o')}
              </Link>
            </Menu.Item>
          }

          {!this.props.demoMode && isCombine &&
            <Menu.Item key="8">
              <Link
                to={`${link.watchCleaning}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959000&startFilter=true`}
              >
                {this.props.translate('report cleaning')}
              </Link>
            </Menu.Item>
          }

          { !this.props.demoMode &&
            <Menu.Item key="9">
              <Link
                to={`${link.watchExport}?machine=${
                  this.props.machine.id
                }&time=${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}000000000,${moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'YYMMDD',
                )}235959000`}
              >
                {this.props.translate('export')}
              </Link>
            </Menu.Item>
          }
        </Menu>
      )
    }

    dropDatePicker = () =>
      <div className="machine-detail__top-time">
        <WrapSoloPicker
          applyDate={this.changeDate}
          imei={this.props.machine.imei}
          startDay={this.state.day}
          history={this.props?.config?.HISTORY}
        />
      </div>
    ;

    render() {
      if(!Object.keys(this.props.machine).length) {
        return <div>{this.props.translate('dont machine')}</div>
      }

      return (
        <div className="machine-detail">
          <div
            className="machine-detail__top"
            ref={calendar => this.calendar = calendar}
          >
            <div className="machine-detail__top-wrap">
              <p className="machine-detail__activeDate">
                {moment(this.state.day, 'YYMMDDHHmmss000').format(
                  'DD.MM.YYYY',
                )}
              </p>
              <ButtonGroup>
                <Button
                  onClick={() =>
                    this.setState({ openDate: !this.state.openDate })
                  }
                  loading={this.state.load}
                  className="machine-detail__button-date"
                  type="primary"
                  icon="calendar"
                >
                  {this.props.translate('Set a date')}
                </Button>
                <Dropdown
                  overlay={() => this.dropAnalyticMenu()}
                  placement="topRight"
                  trigger={['click']}
                >
                  <Button className="machine-detail__button-analytic">
                    {this.props.translate('watch')} <Icon type="down" />
                  </Button>
                </Dropdown>
              </ButtonGroup>
            </div>
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionEnterTimeout={200}
              transitionLeaveTimeout={100}
            >
              {this.state.openDate ? this.dropDatePicker() : null}
            </ReactCSSTransitionGroup>
          </div>

          {Object.keys(this.props.machine).length &&
            <ComposedComponent
              translate={this.props.translate}
              imei={this.props.machine.imei}
              load={this.state.load}
              detailInformation={this.state.detailInformation}
              detailPosition={this.state.detailPosition}
              machine={this.props.machine}
              mainInfoData={this.state.mainInfoData}
              carrier={this.state.carrier}
              reload={this.allRequests}
              today={
                moment(this.state.day, 'YYMMDDHHmmss000').format('YYMMDD') ===
                moment().format('YYMMDD')
              }
              from={
                this.state.day
                  ? moment(this.state.day, 'YYMMDDHHmmss000').format('YYMMDD') +
                    '000000000'
                  : null
              }
              to={
                this.state.day
                  ? moment(this.state.day, 'YYMMDDHHmmss000').format('YYMMDD') +
                    '235959000'
                  : null
              }
              period={moment(this.state.day).format('DD.MM.YYYY')}
              demoMode={this.props.demoMode}
              equipment={this.state.equipment}
              driver={this.state.driver}
            />
          }
        </div>
      )
    }
  }

  return connect(
    (state: AppStateType) => ({

      demoMode: state.user.demoMode,

      users: state.users.get('users'),

      info: state.user.info,

      config: state.user.config,

      // eslint-disable-next-line no-extra-parens
      machine: (state.users.get('users') as List<UserType>) // todo убрать immutablejs из AppStateType и всех дочерних элементов
        .toJS()
        .reduce((result: UnitType, user: UserType): UnitType => {
          const queryParams = Object.fromEntries(new URLSearchParams(window.location.search))

          const foundUnit = user.units.units.find(
            unit => unit.id === Number(queryParams.machine),
          )

          return foundUnit || result
        }, {}),
    }),

    dispatch => ({

      //@ts-expect-error
      startLoadTrack: data => dispatch(startLoadTrack(data)),

      //@ts-expect-error
      deleteTrack: data => dispatch(deleteTrack(data)),

      //@ts-expect-error
      demoTrack: imei => demoTrack(imei, dispatch),

      //@ts-expect-error
      updateDataMachine: id => dispatch(updateDataMachine(id)),

      requestTrackObservable: (data: RequestTrackData) => requestTrack.observableTrack(data, dispatch),

      requestTrack: (data: RequestTrackData) => requestTrack.getTrack(data, dispatch, 'default'),
    }),
  )(withTranslate(HOC))
}
